import React from "react"
import {
  Results,
  ResultsItem,
  ResultsHeader,
  ResultsHeading,
  HR,
  ResultsTotal,
  Title,
  Description,
  Metadata,
  MetaText,
  BackToTop,
} from "../styles/ResultsGrid.styled"
import Pagination from "./Pagination"
import CalendarIcon from "../../../assets/icons/calendar.svg"

const ResultsGrid = ({
  activePosts,
  currentPage,
  pageNumbers,
  paginate,
  totalPages,
}) => {
  const endTotal = (currentPage, activePosts) => {
    let endTotal
    if (currentPage === 1 && currentPage === 1 && currentPage === 10) {
      endTotal = currentPage + 9
    } else if (currentPage === 1 && currentPage < 10) {
      endTotal = activePosts.length
    } else {
      endTotal = currentPage * 10 - 10 + activePosts.length
    }
    return endTotal
  }
  return (
    <Results>
      <ResultsHeader>
        <ResultsHeading>
          <HR />
          All Results
        </ResultsHeading>
        <ResultsTotal>{`Showing ${
          currentPage === 1 ? "1" : currentPage * 10 - 10
        } - ${endTotal(
          currentPage,
          activePosts
        )} of ${totalPages}`}</ResultsTotal>
      </ResultsHeader>
      {activePosts.map((item, index) => {
        const slug = item?.externalUrl ? item.externalUrl : item?.slug
        const target = item?.externalUrl ? "_blank" : "_self"
        return (
          <ResultsItem>
            <Title href={slug} target={target}>
              {item.title}
            </Title>
            <Description>{item.description}</Description>
            {(item?.type || item?.formattedDate) && (
              <Metadata>
                {item?.type && <MetaText>{item?.type}</MetaText>}
                {item?.formattedDate && (
                  <MetaText>
                    <img className="icon" src={CalendarIcon} />
                    {`Published on ${item?.formattedDate}`}
                  </MetaText>
                )}
              </Metadata>
            )}
          </ResultsItem>
        )
      })}
      <Pagination
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        paginate={paginate}
      />
      <BackToTop href="#top">BACK TO TOP</BackToTop>
    </Results>
  )
}

export default ResultsGrid
